






















































import Vue from 'vue';
import { IDiseaseInfo } from '@/interfaces/IDisease';
import { IExternalSource } from '@/interfaces/IExternalSource';

import ExternalSource from './ExternalSource.vue';
import MoreLess from './MoreLess.vue';

export default Vue.extend({
  components: {
    ExternalSource,
    MoreLess,
  },
  computed: {
    medgenUrl(): string {
      return `https://www.ncbi.nlm.nih.gov/medgen/${this.diseaseInfo.disease.cui}`;
    },
  },
  methods: {
    hpoUrl(hpoId: string): string {
      return `https://hpo.jax.org/app/browse/term/${hpoId}`;
    },
    mondoUrl(mondoId: string): string {
      return `https://monarchinitiative.org/disease/${mondoId}`;
    },
    diseaseOntologyUrl(doId: string): string {
      return `https://disease-ontology.org/?id=${doId}`;
    },
    mapUrls(urlfunc: (id: string) => string, urls: string[]): IExternalSource[] {
      return urls.map((url: string) => ({
        href: urlfunc(url), title: url,
      }));
    },
  },
  props: {
    diseaseInfo: Object as () => IDiseaseInfo,
  },
  metaInfo() {
    const diseaseInfo = this.$props.diseaseInfo;
    let description;
    if (diseaseInfo.summary) {
      description = diseaseInfo.summary.text;
    } else {
      description = `${diseaseInfo.disease.name}`;
    }
    if (description.length > 160) {
      description = description.substring(0, 157).concat('...');
    }
    return {
      meta: [{ name: 'description', content: description }],
    };
  },
});
