















import Vue from 'vue';
import { IExternalSource } from '@/interfaces/IExternalSource';

export default Vue.extend({
  props: {
    sourceType: String,
    links: Array as () => IExternalSource[],
  },
});
