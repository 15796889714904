



















































import Vue from 'vue';
import axios from 'axios';

import DiseaseFacets from '@/facets/DiseaseFacets';
import Facet from '@/models/Facet';
import SearchableDisease from '@/models/SearchableDisease';

import FacetList from '@/components/FacetList.vue';
import DiseaseCard from '@/components/DiseaseCard.vue';
import SearchResultsToolbar from '@/components/SearchResultsToolbar.vue';
import Sidepanel from '@/components/Sidepanel.vue';
import { IDiseaseInfo } from '@/interfaces/IDisease';

export default Vue.extend({
  created() {
    this.$trackEvent('disease_view', {
      disease_id: this.$route.params.id,
    });
    this.tab = this.facets.findIndex((f: Facet) => f.name === this.$route.params.tab);
  },
  components: {
    FacetList,
    DiseaseCard,
    SearchResultsToolbar,
    Sidepanel,
  },
  data: () => ({
    facets: DiseaseFacets,
    diseaseInfo: {} as IDiseaseInfo,
    tab: 0,
  }),
  methods: {
    async getCurrentDisease(id: string) {
      const apiUrl = `${this.$apiPrefix}/diseases/${id}/info/`;
      try {
        const response = await axios.get(apiUrl);
        return response.data;
      } catch (err) {
        return null;
      }
    },
    updateTab(index: number): void {
      this.tab = index;
      this.$router.push({
        name: 'DiseaseSearchResults',
        params: {
          tab: this.facets[this.tab].name,
        },
      });
    },
  },
  async mounted() {
    this.$store.commit('sidepanel/close');
    this.diseaseInfo = await this.getCurrentDisease(this.$route.params.id);
    this.$store.commit('setEntity', {
      selectedSearchableEntity: new SearchableDisease({
        disease: this.diseaseInfo.disease,
      }),
    });
  },
});
